import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Vote from "./vote-model";
import VotingBar from "./VotingBar";
import "./Voter.scss";
import ModalWrapper from "../modal-wrapper/ModalWrapper";
import CommentReminder from "../../pages/deadlines/components/modal-content/CommentReminder";
import { voteType } from "../../../utils/constants";

class Voter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCommentReminder: false,
      lastUpdatedVote: null,
      enableAwaitingAnimation: false,
    };
  }

  handleVote = (vote) => {
    const { votes, userID } = this.props;
    let currentVote = votes.filter((e) => e.userID === userID);
    currentVote = currentVote.length > 0 ? currentVote[0] : null;

    const isNewComment = currentVote == null;
    const voteObj = new Vote(
      vote,
      this.props.deadlineUri,
      this.props.userID,
      !isNewComment ? currentVote.uri : null,
    );

    const isRemoveComment = isNewComment
      ? false
      : currentVote.vote.value === voteObj.vote;

    if (vote === voteType.no && !isRemoveComment) {
      this.setState({ showCommentReminder: true });
    }

    this.setState({ lastUpdatedVote: vote, enableAwaitingAnimation: false });
    setTimeout(() => {
      this.setState({ enableAwaitingAnimation: true });
    }, 1000);

    if (isNewComment) {
      this.props.createVote(voteObj);
    } else {
      if (isRemoveComment) {
        this.props.removeVote(voteObj);
      } else {
        this.props.updateVote(voteObj);
      }
    }

    this.props.onChangeVote(voteObj);
  };

  closeCommentReminder = () => {
    this.setState({
      showCommentReminder: false,
      disabled: false,
    });
  };

  checkUserVote = (type) => {
    const { votes, userID, updateInProgress } = this.props;

    if (votes && votes.length && !updateInProgress) {
      let userVote = votes.filter((vote) => vote.userID === userID);

      return userVote.length > 0 && userVote[0].vote.value === type;
    }

    return false;
  };

  isVoteAwaiting(type) {
    return (
      this.state.enableAwaitingAnimation &&
      this.state.lastUpdatedVote === type &&
      this.props.updateInProgress
    );
  }

  renderVotingBar(type, total) {
    const {
      votes,
      statistics,
      headline,
      disableVoting,
      updateInProgress,
      isMemberInCurrentCommittee,
      deadlineStage,
    } = this.props;

    const voteTypeValue = voteType[type];
    const votesCount = statistics ? statistics[type] : 0;

    return (
      <VotingBar
        className={type}
        voteType={voteTypeValue}
        onChange={() => this.handleVote(voteTypeValue)}
        checked={this.checkUserVote(voteTypeValue)}
        calcPercentage={(total != 0 ? (votesCount / total) * 100 : 0).toFixed(
          2,
        )}
        countVotes={votesCount}
        allVotes={votes}
        headline={headline}
        hideVoting={!isMemberInCurrentCommittee || disableVoting}
        disabled={disableVoting || updateInProgress}
        isAwaiting={this.isVoteAwaiting(voteTypeValue)}
        deadlineStage={deadlineStage}
      />
    );
  }

  render() {
    const { t, statistics, deadlineStage, committee } = this.props;
    const total = statistics ? statistics.total : 0;
    const totalReview = statistics ? statistics.totalReview : 0;
    // const isIsoAndCd =
    //   committee.split("/")[0] === "ISO" && deadlineStage === "30";

    return (
      <div className="voter">
        <h3>{t("deadlines.details.recommendation")}</h3>
        <form>
          {deadlineStage !== "90" /** && !isIsoAndCd */ && (
            <>
              {this.renderVotingBar("yes", total)}
              {this.renderVotingBar("no", total)}
              {this.renderVotingBar("abstain", total)}
            </>
          )}
          {deadlineStage === "90" && (
            <>
              {this.renderVotingBar("confirm", totalReview)}
              {this.renderVotingBar("revise", totalReview)}
              {this.renderVotingBar("withdraw", totalReview)}
              {this.renderVotingBar("abstain", totalReview)}
            </>
          )}
        </form>
        <ModalWrapper
          isOpen={this.state.showCommentReminder}
          requestCloseHandler={() =>
            this.setState({ showCommentReminder: false })
          }
          disableSlide
        >
          <CommentReminder
            closeHandler={this.closeCommentReminder}
            deadlineUri={this.props.deadlineUri}
          />
        </ModalWrapper>
      </div>
    );
  }
}

export default withTranslation("common")(Voter);
